main {
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
}

a {
  text-decoration: none;
  color: white;
}

header {
  background-color: #333;
  position: sticky;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  color: white;
  & h1 {
    font-size: 20px;
    text-align: center;
  }
}

.tab {
  max-width: 800px;
  margin: auto;

  & .tab-title {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 20px;
    background: linear-gradient(
      to bottom,
      yellow 60%,
      blue 50%,
      blue 75%,
      red 75%
    );
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
  }
}

.tab-header {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
}

.tab-header ul {
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: #fff;
}

.tab-header ul li {
  cursor: pointer;
  padding: 10px;
  background-color: #fff;
  color: #03678f;
  transition: all 0.3s ease;
  border-top: 4px solid transparent;
  width: 100%;
  text-align: center;
  list-style: none;

  &:hover {
    background-color: #dbe9ef;
  }

  &.active {
    border-top: 4px solid #277c9f;
    background-color: #f5f5f5;
  }

  & button {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
    color: inherit;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.tab-content {
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;

  & section {
    display: none;

    &.active {
      display: block;
    }
  }
}
