*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  color: black;
  position: relative;
  height: 100vh;
}

