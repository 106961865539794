.political-parties-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.political-parties-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.political-party-item {
  margin-bottom: 20px;
}

.political-party-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #03678f;
  border-bottom: 2px solid #277c9f;
  padding-bottom: 5px;
}

.political-party-name {
  font-weight: bold;
}

.toggle-button {
  font-size: 0.9em;
  background-color: transparent;
  border: none;
  color: #03678f;
  cursor: pointer;
  text-decoration: underline;
}

.presidents-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.president-item {
  margin-bottom: 15px;
}

.president-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.president-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.president-details {
  flex: 1;
}

.president-name {
  font-size: 1.1em;
  color: #03678f;
  margin-bottom: 5px;
}

.president-description {
  font-size: 0.9em;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

.president-description.full {
  -webkit-line-clamp: unset;
  overflow: visible;
  text-overflow: unset;
}

.toggle-description-button {
  font-size: 0.9em;
  background-color: transparent;
  border: none;
  color: #03678f;
  cursor: pointer;
  text-decoration: underline;
}

.title {
  text-align: center;
  font-size: 2em;
  margin-top: 20px;
  color: #03678f;
}