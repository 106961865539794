.title {
  text-align: center;
  font-size: 2em;
  margin-top: 20px;
  color: #03678f;
}

.attractions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.deparment-container{
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.department-section {
  width: 100%;
}

.department-title {
  font-size: 1.5em;
  color: #277c9f;
  border-bottom: 2px solid #277c9f;
  margin-bottom: 10px;
}

.attraction-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  transition: transform 0.2s;
}

.attraction-card:hover {
  transform: scale(1.05);
}

.attraction-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.attraction-info {
  text-align: center;
}

.attraction-name {
  font-size: 1.2em;
  color: #4b92af;
}

.attraction-description {
  font-size: 0.9em;
  color: #555;
  margin: 10px 0;
}

.attraction-map-link {
  color: #86ab2e;
  text-decoration: none;
  font-weight: bold;
}

.attraction-map-link:hover {
  text-decoration: underline;
}

.city-title {
    font-size: 1.3em;
    color: #03678f;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .city-section {
    margin-bottom: 30px;
  }
  