.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

thead {
  background-color: #03678f;
  color: #fff;
}

th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  font-size: 1em;
  text-transform: uppercase;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

td {
  font-size: 0.9em;
  color: #555;
}

tbody tr:last-child td {
  border-bottom: none;
}
