.airports-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.department-section {
  margin-bottom: 30px;
}

.department-title {
  font-size: 1.5em;
  color: #03678f;
  margin-bottom: 10px;
  border-bottom: 2px solid #03678f;
  padding-bottom: 5px;
}

.airport-count {
  font-size: 1em;
  color: #555;
  margin-bottom: 15px;
}

.airport-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-left: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.city-name {
  font-size: 1.3em;
  color: #4b92af;
  margin-bottom: 5px;
}

.airport-name {
  font-size: 1.2em;
  color: #277c9f;
  margin-bottom: 5px;
}

.airport-codes,
.airport-coordinates {
  font-size: 0.9em;
  color: #555;
}

.no-results {
  font-size: 1.2em;
  color: #8f032e;
  text-align: center;
  margin-top: 20px;
}

.title {
  text-align: center;
  font-size: 2em;
  margin-top: 20px;
  color: #03678f;
}
